import {
  Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition
} from "@headlessui/react";
import cn from "classnames";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { Fragment } from "react";
import { useController } from "react-hook-form";

const collator = new Intl.Collator("de-AT");

/**
 *
 * @param props0 - The root object
 * @param props0.label - The root object
 * @param props0.selected - The root object
 * @param props0.value - The root object
 * @param props0.withCheck - The root object
 * @example
 */
const Option = ({
  label,
  selected,
  value,
  withCheck
}) => (
  <ListboxOption
    value={value}
    className={({ focus }) => cn(
      "relative cursor-default select-none py-1 px-1.5",
      {
        "bg-gray-200": focus && !selected,
        "bg-gray-300": selected,
        "pl-10": withCheck,
        white: !focus && !selected
      }
    )}
  >
    <span
      className={`block truncate ${selected ? "font-medium" : "font-normal"
      }`}
    >
      {label}
    </span>

    {
      (selected && withCheck)
        ? (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
            <CheckIcon aria-hidden="true" className="size-5" />
          </span>
        )
        : null
    }
  </ListboxOption>
);

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.defaultValue - The root object
 * @param props0.label - The root object
 * @param props0.multiple - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.options - The root object
 * @param props0.withCheck - The root object
 * @param props0.labelClassName - The root object
 * @example
 */
const Select = ({
  className,
  control,
  defaultValue,
  label,
  labelClassName,
  multiple = false,
  name,
  onChange = () => { },
  options,
  withCheck = true
}) => {
  const {
    field: {
      onBlur, onChange: innerOnChange, value
    }
  } = useController({
    control,
    defaultValue: defaultValue ?? null,
    name
  });

  const flatOptions = options
    .flatMap((option) => (Array.isArray(option?.value)
      ? option.value
      : option));

  return (
    <Listbox

      {...{
        multiple,
        onBlur,
        onChange: (e) => {
          innerOnChange(e);
          onChange(e);
        },
        value: multiple ? value : value?.value
      }}
      by={multiple ? "value" : undefined}
    >
      <div className={cn("relative", className)}>
        {label ? <Label className={labelClassName}>{label}</Label> : null}

        <ListboxButton className="focus:outline-hidden shadow-xs relative w-full cursor-default rounded-sm border border-gray-300 bg-white py-0.5 pl-1.5 pr-10 text-left focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <span className="block truncate">
            {
              multiple
                ? (value.length === 0
                  ? "Keine Auswahl"
                  : value
                    .toSorted(({ value: valueA }, { value: valueB }) => {
                      options.findIndex(({ value }) => value === valueA) - options.findIndex(({ value }) => value === valueB);
                    })
                    .map(({ label }) => label)
                    .join(", "))
                : flatOptions.find((option) => value === option.value)?.label
            }
          </span>

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronsUpDownIcon
              aria-hidden="true"
              className="size-5 text-gray-400"
            />
          </span>
        </ListboxButton>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions as="ul" className="focus:outline-hidden absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black/5" modal={false}>
            {options
              .map((option, index) => {
                const {
                  label, shown = true, value: optionValue
                } = option;

                if (Array.isArray(optionValue)) {
                  return (
                    <li className="flex flex-col" key={index}>
                      <span className="w-full bg-gray-100 px-0.5 py-1.5 text-sm font-medium">{label}</span>

                      <ul>
                        {
                          optionValue
                            .filter(({ shown }) => shown)
                            .map((groupOption, groupOptionIndex) => (
                              <Option
                                key={groupOptionIndex}
                                label={groupOption.label}
                                selected={groupOption.value === value}
                                value={groupOption.value}
                                withCheck={withCheck}
                              />
                            ))
                        }
                      </ul>
                    </li>
                  );
                }

                if (shown) {
                  return (
                    <Option
                      key={multiple ? option.value : index}
                      label={label}
                      selected={multiple ? Boolean(value.find(({ value }) => optionValue === value)) : optionValue === value}
                      value={multiple ? option : optionValue}
                      withCheck={withCheck}
                    />
                  );
                }

                return null;
              })}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
