import API from "../modules/api.js";

const loadStateOptions = async (inputValue) => {
  const urlSearchParameters = new URLSearchParams({ search: inputValue });

  const { data: states } = await API.get(`/geography/states?${urlSearchParameters}`);

  return states
    .map(({
      stateId,
      stateName
    }) => ({
      id: stateId,
      label: stateName,
      value: stateId
    }));
};

export default loadStateOptions;
