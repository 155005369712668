import * as cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.className - The root object
 * @example
 */
const PageHeader = ({ children, className }) => (
  <div className={cn("mx-auto max-w-7xl px-4 sm:px-6 lg:px-8", className)}>
    {children}
  </div>
);

export default PageHeader;
