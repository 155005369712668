import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import UnitReviewSlide from "./unit-review-slide.jsx";

/**
 *
 * @example
 */
const UnitReview = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editPossible, editUnitPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible || editUnitPossible;

  return (
    <TabListContainer>

      <UnitReviewSlide hide={hideSlide} project={slideData} projectId={id} visible={slideVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Letzte Kontrolle (Objekte)" />

        <DescriptionContainer>
          <div className="mb-2 grid grid-cols-3 gap-2 bg-gray-100 p-2">

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Letzte Erfassung (Angebote)</p>

              {project?.last_unit_offer_research_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_unit_offer_research_date, "day")}</span>

                    <span> ({project?.last_unit_offer_researcher?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_unit_offer_research_date &&
                <p>-</p>}
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Letzte KV Erfassung/Kontrolle</p>

              {project?.last_unit_sale_research_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_unit_sale_research_date, "day")}</span>

                    <span> ({project?.last_unit_sale_researcher?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_unit_sale_research_date &&
                <p>-</p>}
            </div>
          </div>
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide(project)}>
                  <EditIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default UnitReview;
