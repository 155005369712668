/* eslint-disable max-lines-per-function */
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from "@headlessui/react";
import cn from "classnames";
import { SearchIcon, XIcon } from "lucide-react";
import {
  debounce, isPlainObject, noop
} from "radashi";
import {
  useCallback, useEffect, useState
} from "react";
import { useController } from "react-hook-form";

const defaultDisplayValue = (value) => (isPlainObject(value) ? value.label : value);

const getSearchScore = (inputValue, option) => {
  const match = option.match(inputValue);

  if (match === null) {
    return 0;
  }

  return (inputValue.length / option.length) / (match.index + 1);
};

const ComboInput = ({
  by = "id",
  control,
  displayValue = defaultDisplayValue,
  label,
  name,
  onBlur: customOnBlur = noop,
  onChange: customOnChange = noop,
  options,
  reset,
  setValue,

  onClose: customOnClose = customOnBlur
}) => {
  const isStatic = Array.isArray(options);

  const [filteredOptions, setFilteredOptions] = useState(isStatic ? options : []);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isStatic) {
      setFilteredOptions(options);
    }
  }, [options]);

  const {
    field: {
      disabled,
      onBlur,
      onChange,
      ref,
      value
    }
  } = useController({
    control,
    name
  });

  const handleChange = (changedValue) => {
    console.log(JSON.stringify({ changedValue }));
    customOnChange(changedValue);
    onChange(changedValue);
  };

  const debounced = useCallback(
    debounce(
      {
        delay: 200,
        leading: true
      },
      async (inputValue) => {
        const response = await options(inputValue);

        setFilteredOptions(response);
        setIsLoading(false);
      }
    ),
    [debounce]
  );

  const handleInput = async ({ target: { value: inputValue } }) => {
    if (inputValue) {
      setIsEmpty(false);
      if (isStatic) {
        const normalizedInputValue = inputValue
          .toLocaleLowerCase()
          .trim();

        setFilteredOptions(
          options
            .filter((option) => (
              displayValue(option)
                .toLocaleLowerCase()
                .includes(normalizedInputValue)
            ))
            .toSorted((resultA, resultB) => {
              const scoreA = getSearchScore(normalizedInputValue, displayValue(resultA).toLocaleLowerCase());
              const scoreB = getSearchScore(normalizedInputValue, displayValue(resultB).toLocaleLowerCase());

              return scoreB - scoreA;
            })
        );
      }
      else {
        setIsLoading(true);
        debounced(inputValue);
      }
    }
    else {
      setIsEmpty(true);
      setFilteredOptions([]);
    }
  };

  const handleClose = async (event) => {
    // await customOnClose(event);
    // await onBlur();
    // if (value) {
    //   handleInput({ target: { value: "" } });
    // }
    // else {
    //   setFilteredOptions([]);
    //   setIsEmpty(true);
    // }
  };

  const handleBlur = async (event) => {
    // await customOnBlur(event);
    // await onBlur();
    // if (value) {
    //   handleInput({ target: { value: "" } });
    // }
    // else {
    //   setFilteredOptions([]);
    //   setIsEmpty(true);
    // }
  };

  return (
    <Combobox
      immediate
      as="div"
      by={by}
      className="relative w-full"
      onChange={handleChange}
      onClose={handleClose}
      {...{
        disabled,
        name,
        value
      }}
    >
      <ComboboxInput
        aria-autocomplete="list"
        aria-expanded="false"
        aria-haspopup="true"
        aria-label={label}
        autoCapitalize="none"
        autoComplete="cc-csc"
        autoCorrect="off"
        className="focus:outline-hidden h-10 w-full  rounded-sm border border-gray-300 pl-10 text-sm placeholder:text-gray-300 focus:border-gray-700 focus:ring-gray-700"
        onBlur={handleBlur}
        onChange={handleInput}
        placeholder={label}
        role="combobox"
        spellCheck="false"
        {...{
          displayValue,
          name,
          ref
        }}
      />

      <div className="absolute left-0 top-0 flex size-10 h-full items-center justify-center">
        <SearchIcon
          className="size-5 text-gray-400"
        />
      </div>

      <button
        type="button"
        className={cn(
          "absolute right-0 top-0 flex size-10 h-full items-center justify-center",
          {
            hidden: !value
          }
        )}
        onClick={() => {
          setValue(name, null);
        }}
      >
        <XIcon
          className="size-5 text-gray-400"
        />
      </button>

      <ComboboxOptions
        transition
        as="ul"
        className="absolute z-20 mt-2 max-h-60 w-full overflow-hidden rounded-sm border border-gray-300 bg-white shadow-lg empty:invisible "
        modal={false}
        portal={false}
      >
        <div className="max-h-60 overflow-auto rounded-sm">
          {
            filteredOptions.map((option) => (
              <ComboboxOption
                as="li"
                className="px-4 py-2 data-[focus]:bg-blue-100 data-[selected]:bg-gray-200"
                key={option[by]}
                value={option}
              >
                {displayValue(option)}
              </ComboboxOption>
            ))
          }

          {
            filteredOptions.length === 0
              ? (
                <div className="w-full px-4 py-2 text-center text-gray-400">
                  {
                    isLoading
                      ? "Lädt..."
                      : (
                        isEmpty
                          ? "Suchen..."
                          : "Keine Ergebnisse gefunden."
                      )
                  }
                </div>
              )
              : null
          }
        </div>

      </ComboboxOptions>

    </Combobox>
  );
};

export default ComboInput;
