import { useState } from "react";

import { dummyUnitObject } from "~/src/features/project-units/handlers.js";

import ProjectUnit from "~/src/ui/project-unit/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.units - The root object
 * @example
 */
const ProjectUnitBulkEdit = ({ units }) => (
  <div>
    <h3 className="text-lg font-medium leading-6 text-gray-900">
      Bulk Editierung!
    </h3>

    <div className="mt-2">
      <p className="pb-3 text-sm text-gray-500">
        {units ? `Es werden ${units.length} Units editiert. Die Änderungen betreffen folgende Units:` : null}
      </p>
    </div>

    <div className="max-h-72 overflow-y-scroll rounded-lg bg-gray-200 px-5" >
      {units
        ? units?.map((unit) => (
          <div className="relative pt-6" key={`unitDummy_${unit.position}`}>
            <ProjectUnit automated isDummy compact={false} showDeleteDocument={false} showEditActions={false} showEditDocument={false} showSlide={false} unit={unit} />

          </div>
        ))
        : null}
    </div>
  </div>

);

export default ProjectUnitBulkEdit;
