import Joi from "joi";

import { coordinatesValidation } from "./helpers.js";

const initialValues = {
  cityZipcode: {
    id: "",
    cityId: "",
    label: "",
    zipcodeId: ""
  },
  coordinates: "",
  default_location: false,
  housenumber: "",
  street: {
    id: "",
    label: "",
    streetId: ""
  }
};

/**
 *
 * @param locationData
 * @example
 */
const generateEditValues = (locationData) => {
  if (locationData) {
    const {
      baseAddressId,
      cityId,
      cityName,
      default_location,
      housenumber,
      lat,
      lng,
      streetId,
      streetName,
      zipcodeId,
      zipcodeZipcode
    } = locationData;

    return {
      cityZipcode: {
        id: baseAddressId,
        cityId,
        label: `${zipcodeZipcode} ${cityName}`,
        zipcodeId
      },
      coordinates: locationData ? `${lng}, ${lat}` : "",
      default_location: Boolean(default_location),
      housenumber: housenumber || "",
      street: {
        id: baseAddressId,
        label: `${streetName} (${zipcodeZipcode} ${cityName})`,
        streetId
      }
    };
  }

  return initialValues;
};

const schema = Joi.object({
  cityZipcode: Joi.object({
    id: Joi.number().positive().empty(""),
    cityId: Joi.number().positive().empty(""),
    label: Joi.string().empty(""),
    zipcodeId: Joi.number().positive().empty("")
  }).unknown(true).empty(null),
  coordinates: Joi.string().empty("").custom(coordinatesValidation, "custom validation")
    .required(),
  default_location: Joi.boolean().required(),
  housenumber: Joi.string().empty(""),
  street: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    label: Joi.string().empty(""),
    streetId: Joi.number().positive().empty("")
      .required()
  }).unknown(true)
});

export { generateEditValues, initialValues };

export default schema;
