import useSWR from "swr";

import API from "~/src/modules/api.js";

/**
 *
 * @param cityId
 * @param stateId
 * @example
 */
const useGeographyStates = () => useSWR(
  "/geography/states",
  async (endpoint) => (await API.get(endpoint)).data
);

export default useGeographyStates;
