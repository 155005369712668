import {
  customActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

const location = {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "coordinates",
      "cityCode",
      "cityId",
      "cityId",
      "cityRegionId",
      "regionCode",
      "regionId",
      "regionStateId",
      "stateId",
      "stateIdentifier",
      "stateNameShort",
      "streetId",
      "townshipId",
      "townshipIdentifier",
      "zipcodeId",
      "city_id",
      "street_id",
      "houseNumber",
      "baseAddressId",
      "streetCode",
      "townshipName",
      "regionName"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["housenumber", "Hausnummer"],
      ["default_location", "Hauptadresse"],
      ["lat", "Koordinaten - Breitengrad"],
      ["lng", "Koordinaten - Längengrad"],
      ["cityName", "Stadt"],
      ["zipcodeZipcode", "PLZ"],
      ["stateName", "Bundesland"],
      ["streetName", "Straße"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  old_default_location: customActivity({
    label: "Alte Hauptadresse",
    value: (data) => {
      if (data) {
        const {
          cityName,
          houseNumber,
          stateNameShort,
          streetName,
          zipcodeZipcode
        } = data;

        return `${streetName}${houseNumber ? ` ${houseNumber}` : ""}, ${zipcodeZipcode} ${cityName}, ${stateNameShort?.toLocaleUpperCase()}`;
      }

      return "";
    }
  })
};

export default location;
