import { useState } from "react";
import { useParams } from "react-router-dom";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import ModalCancelButton from "~/src/ui/buttons/modal-cancel-button/index.jsx";
import ModalCloseButton from "~/src/ui/buttons/modal-close-button/index.jsx";
import ModalSubmitButton from "~/src/ui/buttons/modal-submit-button/index.jsx";
import AlertIcon from "~/src/ui/icons/alert-icon/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";

import { handleUpdate } from "./handlers.js";

const DeleteProjectImageModal = ({
  hide,
  project,
  show
}) => {
  const { id } = useParams();
  const { mutate } = useProject(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showError } = useNotification();

  const handleSubmit = () => {
    handleUpdate({
      id: project.id,
      data: {
        fileName: null,
        imageLandscape: true,
        showPublic: false
      },
      hide,
      mutate,
      setIsSubmitting,
      showError
    });
  };

  return (
    <Modal hide={hide} show={show}>
      <div className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">

        <ModalCloseButton hide={hide} />

        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <AlertIcon />
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Achtung!
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Sind Sie sicher, dass dieser Eintrag gelöscht werden soll?
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 gap-x-2 sm:mt-4 sm:flex sm:flex-row-reverse">
          <ModalSubmitButton color="bg-red-600 text-white hover:bg-red-700 focus:ring-red-600" isSubmitting={isSubmitting} onClick={handleSubmit}>
            Löschen
          </ModalSubmitButton>

          <ModalCancelButton hide={hide} />
        </div>

      </div>
    </Modal>
  );
};

export default DeleteProjectImageModal;
