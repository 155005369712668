import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.location - The root object
 * @param props0.location.id - The root object
 * @param props0.location.default_location - The root object
 * @param props0.location.streetName - The root object
 * @param props0.location.housenumber - The root object
 * @param props0.location.zipcodeZipcode - The root object
 * @param props0.location.cityName - The root object
 * @param props0.location.stateNameShort - The root object
 * @param props0.location.broken - The root object
 * @example
 */
const ProjectLocation = ({
  location: {
    id,
    broken,
    cityName,
    default_location,
    housenumber,
    stateNameShort,
    streetName,
    zipcodeZipcode
  } = {}
}) => (
  <p
    key={id}
    className={cn({
      "bg-red-500/50": broken,
      "font-medium": default_location
    })}
  >
    {streetName} {housenumber}, {zipcodeZipcode} {cityName}, {stateNameShort?.toLocaleUpperCase()}
  </p>
);

export default ProjectLocation;
