import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/locations/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId }) => {
    const {
      cityZipcode: {
        cityId,
        zipcodeId
      } = {},
      coordinates,
      default_location,
      housenumber,
      street: {
        streetId
      }
    } = data;

    const [lat, lng] = coordinates.split(", ");

    return {
      location: {
        cityId,
        default_location,
        housenumber,
        lat,
        lng,
        project_id: projectId,
        streetId,
        zipcodeId
      }
    };
  },
  transformUpdateData: (data, { projectId }) => {
    const {
      cityZipcode: {
        cityId,
        zipcodeId
      } = {},
      coordinates,
      default_location,
      housenumber,
      street: {
        streetId
      }
    } = data;

    const [lat, lng] = coordinates.split(", ");

    return {
      location: {
        cityId,
        default_location,
        housenumber,
        lat,
        lng,
        project_id: projectId,
        streetId,
        zipcodeId
      }
    };
  }
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
