import { shake } from "radashi";
import { useEffect } from "react";

import {
  constructionPhaseFilterLabels,
  marketingPhaseFilterLabels,
  projectResearchFilterLabels,
  sortFilterLabels,
  statusFilterLabels,
  synchronizedFilterLabels,
  unitResearchFilterLabels
} from "~/src/modules/labels.jsx";

import Pagination from "~/src/features/pagination/index.jsx";
import ProjectList from "~/src/features/project-list/index.jsx";
import ProjectsSearchForm from "~/src/features/projects-search-form.jsx";

import useModal from "~/src/hooks/use-modal.js";
import useProjects from "~/src/hooks/use-projects.js";
import useStore from "~/src/hooks/use-store.js";

import ActiveProjectLink from "~/src/ui/active-project-link/index.jsx";
import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

import useActiveUserAutomatedProjects from "../hooks/use-active-user-automated-projects.js";
import useAutomatedProjectQueryParameters from "../hooks/use-automated-project-query-parameters.js";
import useAutomatedProjects from "../hooks/use-automated-projects.js";

/**
 *
 * @example
 */
const ProjectsPage = () => {
  const {
    hide: hideNewModal, show: showNewModal, visible: newModalVisible
  } = useModal();
  const [query, setQuery] = useAutomatedProjectQueryParameters();
  const setSavedQuery = useStore((state) => state.setSavedQuery);

  const {
    page
  } = query;

  const {
    isError,
    isLoading,
    pagination,
    projects
  } = useAutomatedProjects(shake(query, (value) => value === undefined || value === null));

  const { automated_projects: activeUserProjects } = useActiveUserAutomatedProjects();

  useEffect(() => {
    setSavedQuery(query);
  }, [query]);

  const handlePageChange = (value) => {
    setQuery({ page: value });
  };

  if (isError) {
    return null;
  }

  return (
    <Page title="Übersicht Projekte">

      <PageHeader>
        <div className="justify-between sm:flex">
          <div>
            <h2 className="text-2xl font-bold text-gray-800 sm:truncate sm:text-3xl">
              Automatisierte Projekte
            </h2>
          </div>

          <div className="mt-12 sm:mt-0 sm:text-right">
            {activeUserProjects?.length > 0 &&
              <h3 className="mb-2 text-sm font-medium text-gray-700">Meine aktiven Projekte</h3>}

            {activeUserProjects?.map((project) => (
              <div className="mb-3" key={`active_projects_${project.id}`}>
                <ActiveProjectLink automated project={project} />
              </div>
            ))}
          </div>
        </div>
      </PageHeader>

      <MainContainer>

        <ProjectsSearchForm automated />

        <div className="mt-4">
          <Pagination
            labels={["Projekt", "Projekte"]}
            page={page}
            pagination={pagination}
            setPage={handlePageChange}

          />
        </div>

        <ProjectList automated isLoading={isLoading} projects={projects} />

        <Pagination
          labels={["Projekt", "Projekte"]}
          page={page}
          pagination={pagination}
          setPage={handlePageChange}
        />

      </MainContainer>

    </Page>
  );
};

export default ProjectsPage;
