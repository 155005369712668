/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */

/**
 *
 * @param unit
 * @example
 */
const generateSaleEditValues = async (unit) => {
  const {
    company_buyers,
    hide_tz,
    internal_sale_notes,
    kv_id,
    kv_url,
    private_buyers,
    sale_area,
    sale_balkon_area,
    sale_balkon_count,
    sale_data_available,
    sale_date,
    sale_garten_area,
    sale_garten_count,
    sale_keller_area,
    sale_keller_count,
    sale_loggia_area,
    sale_loggia_count,
    sale_notice,
    sale_price_gross,
    sale_price_net,
    sale_room_count,
    sale_terrasse_area,
    sale_terrasse_count,
    tz_number,
    tz_year
  } = unit;

  return {
    hideTz: hide_tz,
    kvId: kv_id || "",
    kvUrl: kv_url || "",
    saleDataAvailable: sale_data_available || false,
    saleDate: sale_date ? new Date(sale_date) : "",
    salePriceGross: sale_price_gross || "",
    salePriceNet: sale_price_net || "",
    tzNumber: tz_number || "",
    tzYear: tz_year ? new Date(String(tz_year)) : "",

    privateBuyers: private_buyers?.length > 0
      ? await Promise.all(
        private_buyers.map(async (
          {
            birthdate,
            city,
            country,
            house_number: houseNumber,
            lat,
            lng,
            name: buyerName,
            notes,
            show: buyerShow,
            street,
            surname,
            title_prefix: titlePrefix,
            title_suffix: titleSuffix,
            zipcode
          }
        ) => ({
          birthdate: birthdate ? new Date(birthdate) : null,
          city: city || "",
          coordinates: null,
          country: country?.id
            ? {
              id: country.id,
              code: country.code,
              label: country.name,
              name: country.name
            }
            : null,
          houseNumber: houseNumber || "",
          name: buyerName || "",
          notes: notes || "",
          show: buyerShow ?? true,
          street: street || "",
          surname: surname || "",
          titlePrefix: titlePrefix || "",
          titleSuffix: titleSuffix || "",
          zipcode: zipcode || ""
        }))
      )
      : [],

    companyBuyers: company_buyers?.length > 0
      ? await Promise.all(
        company_buyers.map(async ({
          city,
          country,
          house_number: houseNumber,
          is_zvr: isZvr,
          lat,
          lng,
          name: buyerName,
          no_reg_number: noRegNumber,
          notes,
          reg_number: regNumber,
          show: buyerShow,
          street,
          zipcode
        }) => ({
          city: city || "",
          coordinates: null,
          country: country?.id
            ? {
              id: country.id,
              code: country.code,
              label: country.name,
              name: country.name
            }
            : null,
          houseNumber: houseNumber || "",
          isZvr: isZvr || false,
          name: buyerName || "",
          noRegNumber: noRegNumber || false,
          notes: notes || "",
          regNumber: regNumber || "",
          show: buyerShow ?? true,
          street: street || "",
          zipcode: zipcode || ""
        }))
      )
      : [],

    saleArea: sale_area || "",

    saleRoomCount: sale_room_count || "",

    saleLoggiaArea: sale_loggia_area || "",
    saleLoggiaCount: sale_loggia_count || "",

    saleBalkonArea: sale_balkon_area || "",
    saleBalkonCount: sale_balkon_count || "",

    saleGartenArea: sale_garten_area || "",
    saleGartenCount: sale_garten_count || "",

    saleTerrasseArea: sale_terrasse_area || "",
    saleTerrasseCount: sale_terrasse_count || "",

    saleKellerArea: sale_keller_area || "",
    saleKellerCount: sale_keller_count || "",

    internalSaleNotes: internal_sale_notes || "",
    saleNotice: sale_notice || ""
  };
};

export default generateSaleEditValues;
