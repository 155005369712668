import cn from "classnames";
import { useParams } from "react-router-dom";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import GoogleMapsIcon from "~/src/ui/icons/google-maps-icon/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

/**
 *
 * @example
 */
const AutomatedProjectLocations = () => {
  const { id } = useParams();
  const {
    automated_project: project,
    isError,
    isLoading
  } = useAutomatedProject(id);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>
      <div className="sm:grid sm:grid-cols-8 sm:gap-4">
        <DescriptionTitle title="Adressen" />

        <DescriptionContainer>
          {project.locations?.map((locationData, index) => (
            <div
              key={`location_${locationData.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0,
                "border-2 border-indigo-700": locationData.default_location
              })}
            >
              {locationData.default_location
                ? (
                  <div className="col-span-4">
                    <p className="inline rounded-sm bg-indigo-300 px-1 py-0.5 text-sm font-medium text-indigo-700">
                      Hauptadresse
                    </p>
                  </div>
                )
                : null}

              <div className="col-span-3">
                <p className="text-sm font-medium text-gray-700">Straße</p>

                <p className="">{locationData.streetName}</p>
              </div>

              <div className="col-span-1" />

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Hausnummer</p>

                <p className="">{locationData.housenumber || "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">PLZ</p>

                <p className="">{locationData.zipcodeZipcode}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Stadt</p>

                <p>{locationData.cityName}</p>
              </div>

              <div className="col-span-1 flex items-center">
                <a
                  href={`https://google.com/maps/place/${locationData.lng},${locationData.lat}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Button
                    className="scale-75 justify-self-start"
                    icon={GoogleMapsIcon}
                    label="Maps"
                  />
                </a>
              </div>
            </div>
          ))}
        </DescriptionContainer>
      </div>
    </TabListContainer>
  );
};

export default AutomatedProjectLocations;
