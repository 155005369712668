import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 20h4', key: 'ni2waw' }],
  ['path', { d: 'M12 16v6', key: 'c8a4gj' }],
  ['path', { d: 'M17 2h4v4', key: 'vhe59' }],
  ['path', { d: 'm21 2-5.46 5.46', key: '19kypf' }],
  ['circle', { cx: '12', cy: '11', r: '5', key: '16gxyc' }],
];

/**
 * @component @name VenusAndMars
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMjBoNCIgLz4KICA8cGF0aCBkPSJNMTIgMTZ2NiIgLz4KICA8cGF0aCBkPSJNMTcgMmg0djQiIC8+CiAgPHBhdGggZD0ibTIxIDItNS40NiA1LjQ2IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTEiIHI9IjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/venus-and-mars
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const VenusAndMars = createLucideIcon('VenusAndMars', __iconNode);

export default VenusAndMars;
