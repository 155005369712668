import {
  dash, isArray, mapEntries, shake, sift
} from "radashi";
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";

import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import GoogleMapsIcon from "~/src/ui/icons/google-maps-icon/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import API from "../../../modules/api.js";
import loadCityZipcodeOptions from "../../_common/load-city-zipcode-options.js";

import { handleCreate, handleUpdate } from "./handlers.js";
import { formatClipboardText } from "./helpers.js";
import schema, { generateEditValues, initialValues } from "./schema.js";

const sanitizeQuery = (query) => mapEntries(
  shake(
    query,
    (value) => (isArray(value) ? sift(value).length === 0 : !value)
  ),
  (key, value) => (
    isArray(value)
      ? [dash(String(key)), sift(value).join(",")]
      : [dash(String(key)), typeof value === "boolean" ? "" : String(value)]
  )
);

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.location - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const LocationSlide = ({
  hide, location: locationData, projectId, visible
}) => {
  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (locationData) {
      setIsEdit(true);
      reset(generateEditValues(locationData));
    }
  }, [reset, locationData]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const handleToGoogleMaps = () => {
    window.open("https://google.com/maps", "_blank", "noopener noreferrer");
  };

  const onSubmit = (data) => {
    const handlerParameters = {
      data,
      hide: handleClose,
      mutate,
      projectId,
      setIsLoading,
      showError,
      showSuccess
    };

    if (locationData) {
      handleUpdate({
        ...handlerParameters,
        id: locationData.id
      });
    }
    else {
      handleCreate(handlerParameters);
    }
  };

  const loadStreetOptions = async (inputValue) => {
    const {
      cityId = null,
      zipcodeId = null
    } = watch("cityZipcode") ?? {};

    const urlSearchParameters = new URLSearchParams(
      sanitizeQuery({
        cities: [cityId],
        excludeDeleted: true,
        groupBy: [
          "cities",
          "zipcodes",
          "regions"
        ],
        limit: 20,
        search: inputValue,
        sortBy: "name",
        zipcodes: [zipcodeId]
      })
    );

    const { data: streets } = await API.get(`/geography/streets?${urlSearchParameters}`);

    return streets
      .map(({
        baseAddressId,
        cityName,
        streetId,
        streetName,
        zipcodeZipcode
      }) => ({
        id: baseAddressId,
        label: `${streetName} (${zipcodeZipcode} ${cityName})`,
        streetId
      }));
  };

  const currentAddress = formatClipboardText(watch());

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Adresse ${locationData ? "bearbeiten" : "erstellen"}`} />

          <ComboField
            optionsAsync
            label="PLZ Filter"
            messages={{ queryEmpty: "Postleitzahl oder Ort eingeben..." }}
            name="cityZipcode"
            options={loadCityZipcodeOptions}
            {...{
              control,
              isEdit,
              resetField,
              setValue,
              watch
            }}
          />

          <ComboField
            optionsAsync
            label="Straße *"
            name="street"
            options={loadStreetOptions}
            {...{
              control,
              isEdit,
              resetField,
              setValue,
              watch
            }}
          />

          <Field
            label="Hausnummer"
            name="housenumber"
            {...{
              errors,
              register
            }}
          />

          <Field
            label="Koordinaten *"
            name="coordinates"
            prefix={(
              <CopyToClipboard onCopy={handleToGoogleMaps} text={currentAddress}>
                <Button
                  className="justify-self-start"
                  icon={GoogleMapsIcon}
                  label="Maps"
                />
              </CopyToClipboard>
            )}
            {...{
              errors,
              register
            }}
          />

          <CheckboxField
            label="Hauptadresse"
            name="default_location"
            {...{
              errors,
              register
            }}
          />
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={formIsLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={formIsLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default LocationSlide;
