/* eslint-disable max-lines-per-function */
import {
  Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition
} from "@headlessui/react";
import cn from "classnames";
import {
  CheckIcon, ChevronDownIcon, XIcon
} from "lucide-react";
import {
  mapEntries, omit, shake
} from "radashi";
import {
  Fragment, useEffect, useState
} from "react";
import {
  FormProvider, useForm, useWatch
} from "react-hook-form";
import {
  NumberParam,
  StringParam, useQueryParam, useQueryParams, withDefault
} from "use-query-params";

import FeedList from "~/src/features/feed-list/index.jsx";

import useFeed from "~/src/hooks/use-feed.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

import loadStateOptions from "../_common/load-state-options.js";
import useGeographyState from "../hooks/use-geography-state.js";
import ComboField from "../ui/forms/combo-field/index.jsx";

const defaultQueryParameters = {
  stateId: null
};

/**
 *
 * @example
 */
const FeedPage = () => {
  const [query, setQuery] = useQueryParams({
    stateId: withDefault(NumberParam, defaultQueryParameters.stateId)
  });

  const { data: state } = useGeographyState(query.stateId);

  const {
    isError,
    isLoading,
    projects
  } = useFeed(shake(query, (value) => value === undefined || value === null));

  let formOptions = {
    defaultValues: mapEntries(
      defaultQueryParameters,
      (key, value) => [key, query[key] || value]
    ),
    resetOptions: {
      keepDefaultValues: true
    }
  };

  if (state) {
    formOptions = {
      ...formOptions,
      values: {
        state: {
          ...state,
          id: state.id,
          label: state.name
        }
      }
    };
  }

  if (!state && !query.stateId) {
    formOptions = {
      ...formOptions,
      values: {
        state: null
      }
    };
  }

  const form = useForm(formOptions);

  const {
    control,
    formState: { errors },
    register,
    reset,
    resetField,
    setValue,
    watch
  } = form;

  const values = useWatch({ control });

  useEffect(() => {
    setQuery({
      ...mapEntries(
        values,
        (key, value) => (
          ["state"].includes(key)
            ? [`${key}Id`, (value === "" ? undefined : value?.id)]
            : [key, (value === null || value === "" ? undefined : value)]
        )
      )
    });
  }, [JSON.stringify({ values })]);

  if (isError) {
    return null;
  }

  return (
    <Page title="KV-Feed">
      <PageHeader>
        <div className="flex justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-800 sm:truncate sm:text-3xl">
              Feed
            </h2>
          </div>

          <FormProvider {...form}>
            <form className="flex flex-col gap-8">
              <ComboField
                compact
                inline
                loadDefaultComboOptions
                noLabel
                optionsAsync
                className="w-64"
                inputClassName="!rounded-sm"
                label="Bundesland"
                name="state"
                options={loadStateOptions}
                {...{
                  control,
                  isEdit: false,
                  resetField,
                  setValue,
                  watch
                }}
              />
            </form>
          </FormProvider>

        </div>
      </PageHeader>

      <MainContainer>
        <FeedList isLoading={isLoading} projects={projects} />
      </MainContainer>

    </Page>
  );
};

export default FeedPage;
