const defaultAutomatedProjectQueryParameters = {
  cityZipcodeId: null,
  constructionPhase: "all",
  displayState: "all",
  includeDisabled: false,
  marketingPhase: "all",
  name: null,
  page: 1,
  researchState: "all",
  sortBy: "updated-desc",
  stateId: null,
  street: null,
  synchronized: "all"
};

export default defaultAutomatedProjectQueryParameters;
