import { useParams } from "react-router-dom";

import { constructionPhaseLabels, marketingPhaseLabels } from "~/src/modules/labels.jsx";

import useProject from "~/src/hooks/use-project.js";

import Badge from "~/src/ui/badges/status-badge/index.jsx";

/**
 *
 * @example
 */
const Trackings = () => {
  const { id } = useParams();
  const { project } = useProject(id);

  return (
    <li className="col-span-1">
      <div className="flex w-full items-center justify-between space-x-6">
        <div className="flex-1 truncate">
          <div>
            <h3 className="truncate font-medium text-gray-700">Projektstatus</h3>

            <div className="mt-2 space-y-6">
              <div className="flex flex-col items-start gap-1">
                {
                  project?.published
                    ? <Badge badgeFor="public" text="Öffentlich" />
                    : <Badge badgeFor="internal" text="Intern" />
                }

                {(project?.published && project?.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
              </div>

              <div>
                <p className="py-.5 rounded-lg bg-gray-600 px-4 text-center text-lg text-white">
                  {project?.timeline?.current_construction_phase ? constructionPhaseLabels[project?.timeline?.current_construction_phase] : null}
                </p>
              </div>

              <div>
                <p className="py-.5 rounded-lg bg-gray-600 px-4 text-center text-lg text-white">
                  {project?.timeline?.current_marketing_phase ? marketingPhaseLabels[project?.timeline?.current_marketing_phase] : null}
                </p>
              </div>

            </div>
          </div>

          {/* <div className="mt-12 text-gray-500">
          <p className="text-gray-700 font-medium">Zuletzt veröffentlicht:</p>

          {project.last_publish_date &&
            <div className="mt-2">
              <p className="text-gray-500 text-sm truncate">
                am {dayjs(project.last_publish_date).format('DD.MM.YYYY HH:mm')}
              </p>
              <p className="text-gray-500 text-sm truncate">
                von {project.last_publisher.email}
              </p>
            </div>
          }

          {!project.last_publish_date &&
            <p className="mt-2 text-gray-500 text-sm truncate">
              Keine Einträge vorhanden
            </p>
          }

        </div> */}
        </div>
      </div>
    </li>
  );
};

export default Trackings;
