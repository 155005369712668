import useSWR from "swr";

import API from "~/src/modules/api.js";

/**
 *
 * @param cityId
 * @param cityZipcodeId
 * @example
 */
const useGeographyCityZipcode = (cityZipcodeId) => useSWR(
  `/geography/base-addresses/${cityZipcodeId}`,
  async (endpoint) => {
    if (!cityZipcodeId) {
      return null;
    }

    return (await API.get(endpoint)).data;
  }
);

export default useGeographyCityZipcode;
