import { camelCase } from "lodash-es";

import * as types from "./types.js";

const formatActivityChange = ({
  activityType, key, otherValue, side, value
}) => {
  const formatter = types?.[camelCase(activityType)]?.[key];

  return formatter
    ? {
      key,
      unitOffer: false,
      unitSale: false,
      ...formatter(value, otherValue, side)
    }
    : {
      key,
      label: key,
      unitOffer: false,
      unitSale: false,
      value: String(value)
    };
};

export default formatActivityChange;
