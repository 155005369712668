import { useForm } from "react-hook-form";

import API from "~/src/modules/api.js";

import ContractList from "~/src/features/contract-list/index.jsx";

import useContracts from "~/src/hooks/use-contracts.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

/**
 *
 * @example
 */
const ContractsPage = () => {
  const {
    control, formState, formState: { errors, values }, handleSubmit, register, resetField, setValue, watch
  } = useForm();

  const { contracts, isLoading } = useContracts({
    ez: watch("ez"),
    kg: watch("kg")?.code
  });

  const loadOptions = async (inputValue) => API.post("/katastralgemeinden/search", {
    q: inputValue
  }).then((res) => res.data.payload.katastralgemeinden.map((kg) => ({
    id: kg.id,
    code: kg.code,
    label: `${kg.code} ${kg.name} (BG ${kg.bezirksgericht.code} ${kg.bezirksgericht.name})`
  }))).catch((error) => {
    console.log(error);
  });

  return (
    <Page title="Kaufverträge">
      <PageHeader>
        <div className="justify-between sm:flex">
          <div>
            <h2 className="text-2xl font-bold text-gray-800 sm:truncate sm:text-3xl">
              Kaufverträge
            </h2>
          </div>
        </div>
      </PageHeader>

      <MainContainer>
        <div className="min-h-screen">
          <div className="mt-4 flex gap-4">
            <ComboField
              inline
              optionsAsync
              className="w-96"
              label="KG *"
              name="kg"
              options={loadOptions}
              messages={{
                queryEmpty: "KG eingeben..."
              }}
              {...{
                control,
                resetField,
                setValue,
                watch
              }}
            />

            <Field
              inline
              className="w-32"
              label="EZ"
              name="ez"
              {...{
                errors,
                register
              }}
            />
          </div>

          <ContractList
            {...{
              contracts,
              isLoading
            }}
          />
        </div>

      </MainContainer>

    </Page>
  );
};

export default ContractsPage;
