import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.stateId - The root object
 * @example
 */
const useFeed = ({ stateId } = {}) => {
  const safeParameters = new URLSearchParams(Object.fromEntries(Object.entries({ stateId }).filter(([key, value]) => Boolean(value))));

  const { data, error } = useSWR(`/feed?${safeParameters}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    projects: data?.payload?.feed
  };
};

export default useFeed;
