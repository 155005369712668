import useSWR from "swr";

import API, { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param cityId
 * @param stateId
 * @example
 */
const useGeographyState = (stateId) => useSWR(
  `/geography/states/${stateId}`,
  async (endpoint) => {
    if (!stateId) {
      return null;
    }

    const response = await API.get(endpoint);

    console.log(response.data);

    return response.data;
  }
);

export default useGeographyState;
