import { mapEntries } from "radashi";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param query
 * @example
 */
const useAutomatedProjects = (query) => {
  const urlSearchParameters = new URLSearchParams(query);

  const { data, error } = useSWR(`/automated-projects?${urlSearchParameters}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload?.pagination,
    projects: data?.payload?.projects
  };
};

export default useAutomatedProjects;
