import cn from "classnames";

import BottomClose from "~/src/ui/buttons/modal/bottom-close.jsx";
import Modal from "~/src/ui/modals/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.company - The root object
 * @param props0.hide - The root object
 * @param props0.visible - The root object
 * @example
 */
const CompanyDetailsModal = ({
  company, hide, visible
}) => (
  <Modal hide={hide} show={visible}>
    <div className="inline-block w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">

      <h3 className="text-lg font-medium">Unternehmensdetails</h3>

      <div className="mt-6 space-y-6">

        <div>
          <h4 className="text-gray-700">Unternehmen suchbar</h4>

          <p
            className={cn("mt-1", {
              "text-red-500 font-medium": !company?.searchable
            })}
          >
            {company?.searchable ? "ja" : "nein"}
          </p>
        </div>

        <div>
          <h4 className="text-gray-700">{company?.is_zvr ? "Vereinsname" : "Firmenname"}</h4>

          <p className="mt-1">{company?.name ? company?.name : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">{company?.is_zvr ? "ZVR-Nr." : "FB-Nr."}</h4>

          <p className="mt-1">{company?.reg_number ? company?.reg_number : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">Interne Bemerkungen</h4>

          <p className="mt-1 whitespace-pre-line">{company?.internal_notes ? company?.internal_notes : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">Straße</h4>

          <p className="mt-1">{company?.street ? company?.street : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">Hausnummer</h4>

          <p className="mt-1">{company?.house_number ? company?.house_number : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">PLZ</h4>

          <p className="mt-1">{company?.zipcode ? company?.zipcode : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">Stadt</h4>

          <p className="mt-1">{company?.city ? company?.city : "-"}</p>
        </div>

        <div>
          <h4 className="text-gray-700">Addresszusatz</h4>

          <p className="mt-1">{company?.additional_address_info ? company?.additional_address_info : "-"}</p>
        </div>

        {company?.country
          ? (
            <div>
              <h4 className="text-gray-700">Land</h4>

              <p className="mt-1">{company?.country ? company?.country : "-"}</p>
            </div>
          )
          : null}

      </div>

      <BottomClose onClick={hide} />

    </div>
  </Modal>
);

export default CompanyDetailsModal;
