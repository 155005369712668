import * as React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { SWRConfig } from "swr";

import ErrorNotification from "~/src/ui/notifications/error-notification/index.jsx";
import NotificationContainer from "~/src/ui/notifications/notification-container/index.jsx";
import SuccessNotification from "~/src/ui/notifications/success-notification/index.jsx";

import NotificationProvider from "~/src/contexts/notificationContext.js";

import App from "./app.jsx";

const container = document.querySelector("#root");

const root = createRoot(container);

root.render(
  <StrictMode>
    <SWRConfig
      value={{
        revalidateOnFocus: false
      }}
    >
      <NotificationProvider>
        <App />

        <NotificationContainer>
          <SuccessNotification />

          <ErrorNotification />
        </NotificationContainer>
      </NotificationProvider>
    </SWRConfig>
  </StrictMode>
);
