/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { SearchIcon } from "lucide-react";
import {
  isEqual, mapEntries, omit
} from "radashi";
import { useEffect, useRef } from "react";
import {
  FormProvider, useForm, useWatch
} from "react-hook-form";

import defaultAutomatedProjectQueryParameters from "../_common/default-automated-project-query-parameters.js";
import defaultProjectQueryParameters from "../_common/default-project-query-parameters.js";
import loadStateOptions from "../_common/load-state-options.js";
import useAutomatedProjectQueryParameters from "../hooks/use-automated-project-query-parameters.js";
import useGeographyCityZipcode from "../hooks/use-geography-city-zipcode.js";
import useGeographyState from "../hooks/use-geography-state.js";
import useGeographyStates from "../hooks/use-geography-states.js";
import useProjectQueryParameters from "../hooks/use-project-query-parameters.js";
import API from "../modules/api.js";
import {
  constructionPhaseFilterLabels,
  marketingPhaseFilterLabels,
  researchFilterOptions,
  sortFilterLabels,
  statusFilterLabels,
  synchronizedFilterLabels
} from "../modules/labels.jsx";
import Button from "../ui/buttons/button/index.jsx";
import CheckboxField from "../ui/forms/checkbox-field/index.jsx";
import ComboInput from "../ui/forms/combo-input.jsx";
import Field from "../ui/forms/field/index.jsx";
import Select from "../ui/forms/select/index.new.jsx";

import loadCityZipcodeOptions from "./_common/load-city-zipcode-options.js";

const ProjectsSearchForm = ({ automated = false }) => {
  const [query, setQuery] = automated
    ? useAutomatedProjectQueryParameters()
    : useProjectQueryParameters();

  const { data: cityZipcode } = useGeographyCityZipcode(query.cityZipcodeId);
  const { data: state } = useGeographyState(query.stateId);
  const { data: states = [] } = useGeographyStates();

  const stateOptions = states.map(({ stateId, stateName }) => ({
    label: stateName,
    value: stateId
  }));

  const defaultQueryParameters = omit(
    automated
      ? defaultAutomatedProjectQueryParameters
      : defaultProjectQueryParameters,
    ["page"]
  );

  const defaultValues = {
    ...defaultQueryParameters,
    cityZipcode: null,
    state: null
  };

  const initialValues = {
    ...defaultValues,
    ...query,
    cityZipcode: cityZipcode
      ? {
        ...cityZipcode,
        label: `${cityZipcode.zipcodeZipcode} ${cityZipcode.cityName}`,
        value: cityZipcode.baseAddressId
      }
      : null,
    state: state
      ? {
        ...state,
        label: state.name,
        value: state.id
      }
      : null
  };

  const form = useForm({ defaultValues: initialValues });

  const {
    control,
    formState: { errors },
    register,
    reset,
    setValue
  } = form;

  useEffect(() => {
    if (cityZipcode && typeof cityZipcode === "object") {
      setValue("cityZipcode", {
        ...cityZipcode,
        label: `${cityZipcode.zipcodeZipcode} ${cityZipcode.cityName}`,
        value: cityZipcode.baseAddressId
      });
    }
  }, [cityZipcode, setValue]);

  useEffect(() => {
    if (state && typeof state === "object") {
      setValue("state", {
        ...state,
        label: state.name,
        value: state.id
      });
    }
  }, [state, setValue]);

  const values = useWatch({ control });

  useEffect(() => {
    // Build the desired query from all form values.
    const desiredQuery = omit(
      {
        ...values,
        cityZipcodeId: values.cityZipcode ? values.cityZipcode.value : null,
        stateId: values.state ? values.state.value : null
      },
      [
        "cityZipcode",
        "state",
        "page"
      ]
    );

    // Early-exit if async data hasn't loaded yet for these fields.
    if (query.cityZipcodeId && desiredQuery.cityZipcodeId === null && !cityZipcode) {
      return;
    }
    if (query.stateId && desiredQuery.stateId === null && !state) {
      return;
    }

    // Compare the desired query with the current query, excluding page.
    const { page, ...currentFilters } = query;

    if (!isEqual(desiredQuery, currentFilters)) {
      setQuery({
        ...desiredQuery,
        page: 1
      });
    }
  }, [
    values,
    query,
    setQuery,
    cityZipcode,
    state
  ]);

  return (
    <FormProvider {...form}>
      <form className="flex w-full flex-col gap-8 pt-8">
        <input autoComplete="false" name="hidden" style={{ display: "none" }} type="text" />

        <div className="grid w-full grid-cols-5 gap-2">
          <Field
            hasLabel={false}
            icon={SearchIcon}
            inputInnerClassName="!rounded-sm"
            label="Name"
            name="name"
            {...{
              errors,
              register
            }}
          />

          <Field
            hasLabel={false}
            icon={SearchIcon}
            inputInnerClassName="!rounded-sm"
            label="Straße"
            name="street"
            {...{
              errors,
              register
            }}
          />

          <ComboInput
            by="value"
            label="Postleitzahl oder Ort"
            name="cityZipcode"
            options={loadCityZipcodeOptions}
            {...{
              control,
              errors,
              register,
              reset,
              setValue
            }}
          />

          <ComboInput
            by="value"
            label="Bundesland"
            name="state"
            options={stateOptions}
            {...{
              control,
              errors,
              register,
              reset,
              setValue
            }}
          />

          <Button
            className="bg-red-800 hover:bg-red-900"
            label="Zurücksetzen"
            onClick={() => {
              reset(defaultValues);
            }}
          />
        </div>

        <div className="grid-cols-18 grid w-full items-end gap-2">
          {automated && (
            <div className="col-span-10">
              <CheckboxField
                errors={errors}
                innerClassName="flex py-0 gap-4 items-center"
                label="Deaktivierte Projekte anzeigen"
                name="includeDisabled"
                register={register}
              />
            </div>
          )}

          {[
            ...(automated
              ? []
              : [
                {
                  className: "col-span-3",
                  label: "Erfassungsstatus",
                  name: "researchState",
                  options: researchFilterOptions
                },
                {
                  className: "col-span-4",
                  label: "Baustatus",
                  name: "constructionPhase",
                  options: constructionPhaseFilterLabels
                },
                {
                  className: "col-span-3",
                  label: "Vermarktungsstatus",
                  name: "marketingPhase",
                  options: marketingPhaseFilterLabels
                }
              ]),
            {
              className: "col-span-3",
              label: "Sortieren nach",
              name: "sortBy",
              options: automated
                ? sortFilterLabels.historic
                : sortFilterLabels.nonHistoric
            },
            {
              className: "col-span-2",
              label: "Anzeigestatus",
              name: "displayState",
              options: statusFilterLabels
            },
            {
              className: "col-span-3",
              label: "Synchronisert",
              name: "synchronized",
              options: synchronizedFilterLabels
            }
          ].map(({
            className, label, name, options
          }) => (
            <Select
              className={cn("text-sm", className)}
              control={control}
              key={name}
              label={label}
              labelClassName="font-medium text-gray-600"
              name={name}
              options={options}
              withCheck={false}
            />
          ))}
        </div>
      </form>
    </FormProvider>
  );
};

export default ProjectsSearchForm;
