import { validationErrors } from "./errors.js";

const validationResolver = (validationSchema) => async (data, context) => {
  const { error, value: values } = validationSchema.validate(data, {
    abortEarly: false
  });

  if (!error) {
    return {
      errors: {},
      values
    };
  }

  return {
    errors: Object.fromEntries(error.details.map(
      (currentError) => {
        const { path } = currentError;

        return (path.length > 0)
          ? [
            path.join("."),
            {
              ...currentError,
              message: validationErrors(currentError.type)
            }
          ]
          : [
            currentError.context.peer,
            {
              ...currentError,
              message: validationErrors(currentError.type)
            }
          ];
      }
    )),
    values: {}
  };
};

export default validationResolver;
