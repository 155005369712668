/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from "@headlessui/react";
import cn from "classnames";
import dayjs from "dayjs";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronsDownIcon,
  XIcon
} from "lucide-react";
import {
  Fragment, useEffect, useState
} from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  DateParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault
} from "use-query-params";

import API from "~/src/modules/api.js";
import { activityNameLabels, activityTypeLabels } from "~/src/modules/labels.jsx";

import ActivityList from "~/src/features/activity-list/index.jsx";
import Pagination from "~/src/features/pagination/index.jsx";

import useActivities from "~/src/hooks/use-activities.js";
import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useProject from "~/src/hooks/use-project.js";
import useUsers from "~/src/hooks/use-users.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import ScrollTopButton from "~/src/ui/buttons/scroll-top-button/index.jsx";
import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import DateField from "~/src/ui/forms/date-field/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

const roleSorting = [
  "editor",
  "unitController",
  "moderator",
  "admin",
  "inactive"
];

/**
 *
 * @example
 */
const ActivitiesPage = () => {
  const { users } = useUsers();

  const sortedUsers = users
    ? [...users]
      .sort((
        { role: roleA, username: usernameA },
        { role: roleB, username: usernameB }
      ) => (
        roleSorting.indexOf(roleA) - roleSorting.indexOf(roleB) ||
        usernameA.localeCompare(usernameB)
      ))
    : [];

  const [activityNames, setActivityNames] = useQueryParam(
    "names",
    withDefault(DelimitedArrayParam, [])
  );
  const [activityTypes, setActivityTypes] = useQueryParam(
    "types",
    withDefault(DelimitedArrayParam, [])
  );
  const [editors, setEditors] = useQueryParam(
    "editors",
    withDefault(
      DelimitedArrayParam,
      []
    )
  );

  const [after, setAfter] = useQueryParam("after", DateParam);
  const [before, setBefore] = useQueryParam("before", DateParam);
  const [project, setProject] = useQueryParam("project", StringParam);
  const [automatedProject, setAutomatedProject] = useQueryParam("automatedProject", StringParam);

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));

  const [globalCollapseLevel, setGlobalCollapseLevel] = useQueryParam(
    "collapseLevel",
    withDefault(NumberParam, 3)
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      before,
      after
    }
  });

  const [
    afterValue,
    beforeValue,
    projectValue
  ] = useWatch({
    control,
    name: [
      "after",
      "before",
      "project",
      "automatedProject"
    ]
  });

  const { project: initialProject } = useProject(project ?? "");
  const { automated_project: initialAutomatedProject } = useAutomatedProject(automatedProject ?? "");

  const {
    activities, isError, isLoading, pagination
  } = useActivities({
    before,
    after,
    automatedProject,
    editors,
    names: activityNames,
    page,
    project,
    types: activityTypes
  });

  const [currentPagination, setCurrentPagination] = useState();

  useEffect(() => {
    if (pagination) {
      setCurrentPagination(pagination);
    }
  }, [pagination]);

  useEffect(() => {
    setAfter(afterValue || undefined);
    setBefore(beforeValue || undefined);

    if (projectValue) {
      setProject(projectValue?.id);
    }
  }, [
    afterValue,
    beforeValue,
    projectValue
  ]);

  useEffect(() => {
    if (initialProject) {
      setValue("project", {
        id: initialProject.id,
        label: initialProject.name
      });
    }
  }, [initialProject]);

  useEffect(() => {
    if (initialAutomatedProject) {
      setValue("automatedProject", {
        id: initialAutomatedProject.id,
        label: initialAutomatedProject.name
      });
    }
  }, [initialAutomatedProject]);

  if (isError) {
    return null;
  }

  const editorLabels = new Map(sortedUsers.map(({ id, username }) => [String(id), username]));

  const selects = [
    [
      activityNames,
      setActivityNames,
      activityNameLabels,
      "Art"
    ],
    [
      activityTypes,
      setActivityTypes,
      activityTypeLabels,
      "Bereich"
    ],
    [
      editors,
      setEditors,
      editorLabels,
      "Personen",
      false
    ]
  ];

  const loadProjectOptions = async (inputValue) => API.get(`/projects?name=${inputValue}&sort=updated-desc`)
    .then((res) => res.data.payload.projects.map((project) => ({
      id: project.id,
      label: project.name
    })))
    .catch((error) => {
      console.log(error);
    });

  return (
    <Page title="Aktivitäten">
      <PageHeader>
        <div className="justify-between sm:flex">
          <h2 className="text-2xl font-bold text-gray-800 sm:truncate sm:text-3xl">
            Aktivitäten
          </h2>

          <div className="flex">
            <Button
              icon={ChevronUpIcon}
              className={cn(
                "rounded-r-none! focus:ring-offset-[-2px] border",
                {
                  "bg-gray-700! border-transparent!": globalCollapseLevel === 1,
                  "bg-white! text-gray-700! border-gray-700! hover:bg-gray-100!": globalCollapseLevel !== 1
                }
              )}
              onClick={() => {
                setGlobalCollapseLevel(1);
              }}
            />

            <Button
              icon={ChevronDownIcon}
              className={cn("-ml-px rounded-none! focus:ring-offset-[-2px] border", {
                "bg-gray-700! border-transparent": globalCollapseLevel === 2,
                "bg-white! text-gray-700! border-gray-700! hover:bg-gray-100!": globalCollapseLevel !== 2
              })}
              onClick={() => {
                setGlobalCollapseLevel(2);
              }}
            />

            <Button
              icon={ChevronsDownIcon}
              className={cn("-ml-px rounded-l-none! focus:ring-offset-[-2px] border", {
                "bg-gray-700! border-transparent": globalCollapseLevel === 3,
                "bg-white! text-gray-700! border-gray-700! hover:bg-gray-100!": globalCollapseLevel !== 3
              })}
              onClick={() => {
                setGlobalCollapseLevel(3);
              }}
            />
          </div>
        </div>
      </PageHeader>

      <MainContainer>
        <div className="mt-4 flex flex-col gap-4">
          <form autoComplete="off" className="grid w-full grid-cols-3 gap-4">
            {selects.map(([
              state,
              setState,
              labels,
              label,
              multiple = true
            ]) => {
              let placeholder = `${label} auswählen`;

              if (state?.length === labels.size) {
                placeholder = "Alle";
              }
              else if (state?.length > 0) {
                placeholder = state.map((key) => labels.get(key)).join(", ");
              }

              return (
                <Listbox
                  as="div"
                  className="relative text-sm"
                  key={label}
                  multiple={multiple}
                  onChange={multiple ? setState : (value) => setState([value])}
                  value={multiple ? state : state?.[0]}
                >
                  <Label className="font-medium text-gray-900">{label}</Label>

                  <ListboxButton className="rounded-xs relative flex w-full items-center justify-between border border-gray-300 py-2 pl-3 pr-20 hover:border-gray-400 focus:border-gray-700 focus:ring-gray-700">
                    <span className="truncate text-gray-600">
                      {placeholder}
                    </span>

                    {state?.length > 0 && (
                      <XIcon
                        className="absolute right-10 h-5 w-10 border-gray-600 text-gray-600"
                        onClick={(e) => {
                          setState([]);
                          e.stopPropagation();
                        }}
                      />
                    )}

                    <ChevronDownIcon className="absolute right-0 h-5 w-10 border-l border-gray-600 text-gray-600" />
                  </ListboxButton>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <ListboxOptions
                      as="ul"
                      className="absolute z-10 mt-1 max-h-60 w-full cursor-pointer overflow-auto rounded-sm bg-white shadow-lg"
                      modal={false}
                    >
                      {[...labels].map(([key, label]) => (
                        <ListboxOption as={Fragment} key={key} value={key}>
                          {({ active, selected }) => (
                            <li
                              className={cn("flex items-center relative pl-10 pr-3 py-2", {
                                "bg-gray-600 text-white": active,
                                "bg-white text-gray-600": !active,
                                "font-base": !selected,
                                "font-medium": selected
                              })}
                            >
                              {selected ? <CheckIcon className="absolute left-0 h-5 w-10" /> : null}

                              <span className="">{label}</span>
                            </li>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              );
            })}

            <DateField
              inline
              label="Ab"
              maxDate={before ? dayjs(before).subtract(1, "day").toDate() : dayjs().toDate()}
              minDate={dayjs("2010").toDate()}
              name="after"
              {...{
                control,
                errors,
                register
              }}
            />

            <DateField
              inline
              label="Vor"
              maxDate={dayjs().add(1, "day").toDate()}
              minDate={after ? dayjs(after).add(1, "day").toDate() : dayjs("2010").add(1, "day").toDate()}
              name="before"
              {...{
                control,
                errors,
                register
              }}
            />

            <ComboField
              inline
              loadDefaultComboOptions
              optionsAsync
              label="Projekt"
              name="project"
              options={loadProjectOptions}
              messages={{
                queryEmpty: "Projektnamen eingeben..."
              }}
              resetField={(name) => {
                resetField(name);
                setProject(undefined);
              }}
              {...{
                control,
                setValue,
                watch
              }}
            />
          </form>

          <Pagination
            labels={["Aktivität", "Aktivitäten"]}
            page={page}
            pagination={currentPagination}
            setPage={(page) => {
              setPage(page);
              window.scrollTo(0, 0);
            }}
          />

          <ActivityList
            {...{
              activities,
              activityTypes,
              isLoading,
              setActivityTypes
            }}
          />

          <Pagination
            labels={["Aktivität", "Aktivitäten"]}
            page={page}
            pagination={currentPagination}
            setPage={(page) => {
              setPage(page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </MainContainer>

      <div className="fixed bottom-4 right-4">
        <ScrollTopButton />
      </div>
    </Page>
  );
};

export default ActivitiesPage;
