import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from "use-query-params";

import defaultAutomatedProjectQueryParameters from "../_common/default-automated-project-query-parameters.js";

const {
  cityZipcodeId: defaultCityZipcodeId,
  constructionPhase: defaultConstructionPhase,
  displayState: defaultDisplayState,
  includeDisabled: defaultIncludeDisabled,
  marketingPhase: defaultMarketingPhase,
  name: defaultName,
  page: defaultPage,
  researchState: defaultResearchState,
  sortBy: defaultSortBy,
  stateId: defaultStateId,
  street: defaultStreet,
  synchronized: defaultSynchronizedFilter
} = defaultAutomatedProjectQueryParameters;

/**
 *
 * @example
 */
const useAutomatedProjectQueryParameters = () => useQueryParams({
  cityZipcodeId: withDefault(NumberParam, defaultCityZipcodeId),
  constructionPhase: withDefault(StringParam, defaultConstructionPhase),
  displayState: withDefault(StringParam, defaultDisplayState),
  includeDisabled: withDefault(BooleanParam, defaultIncludeDisabled),
  marketingPhase: withDefault(StringParam, defaultMarketingPhase),
  name: withDefault(StringParam, defaultName),
  page: withDefault(NumberParam, defaultPage),
  researchState: withDefault(StringParam, defaultResearchState),
  sortBy: withDefault(StringParam, defaultSortBy),
  stateId: withDefault(NumberParam, defaultStateId),
  street: withDefault(StringParam, defaultStreet),
  synchronized: withDefault(StringParam, defaultSynchronizedFilter)
});

export default useAutomatedProjectQueryParameters;
