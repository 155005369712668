import Joi from "joi";

const initialValues = {
  last_unit_offer_research_date: "",
  last_unit_sale_research_date: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    return {
      last_unit_offer_research_date: project.last_unit_offer_research_date,
      last_unit_sale_research_date: project.last_unit_sale_research_date
    };
  }

  return initialValues;
};

const schema = Joi.object({
  last_unit_offer_research_date: Joi.date().empty(null).optional(),
  last_unit_sale_research_date: Joi.date().empty(null).optional()
});

export {
  generateEditValues,
  schema
};
