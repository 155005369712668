import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 * @import { LegacyHookResult } from "./_types/_exports.js";
 * @import { ProjectResponse } from "./use-project/_exports.js"
 */

/**
 * Custom hook to fetch and manage project data.
 *
 * @param {number} id - The project ID
 * @returns {LegacyHookResult<ProjectResponse>} An object containing the project data payload, SWR state and mutate function
 * @example
 * const {
 *  isError,
 *  isLoading,
 *  mutate,
 *  project,
 *  transactionCategories
 * } = useProject(id);
 */
const useProject = (id) => {
  const {
    data, error, mutate
  } = useSWR(`/projects/${id}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    project: data?.payload?.project,
    transactionCategories: data?.payload?.transaction_categories
  };
};

export default useProject;
