import API from "../../modules/api.js";

const loadCityZipcodeOptions = async (inputValue) => {
  const body = {
    q: inputValue
  };

  const { data } = await API.post(
    "/geography/city-zipcode",
    body
  );

  const {
    payload: {
      cities: citiesPayload
    }
  } = data;

  return citiesPayload
    .map(({
      baseAddressId,
      cityId,
      cityName,
      zipcodeId,
      zipcodeZipcode
    }) => ({
      id: baseAddressId,
      cityId,
      label: `${zipcodeZipcode} ${cityName}`,
      value: baseAddressId,
      zipcodeId
    }));
};

export default loadCityZipcodeOptions;
