import { useState } from "react";

import ModalCancelButton from "~/src/ui/buttons/modal-cancel-button/index.jsx";
import ModalCloseButton from "~/src/ui/buttons/modal-close-button/index.jsx";
import ModalSubmitButton from "~/src/ui/buttons/modal-submit-button/index.jsx";
import AlertIcon from "~/src/ui/icons/alert-icon/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";
import ProjectUnitBulkCreation from "~/src/ui/project-unit-bulk-creation/index.jsx";

import { handleCreateBulk } from "./handlers.js";

/**
 *
 * @param props0 - The root object
 * @param props0.data - The root object
 * @param props0.hide - The root object
 * @param props0.show - The root object
 * @param props0.watchData - The root object
 * @example
 */
const CreateBulkModal = ({
  data, hide, show, watchData
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   *
   * @example
   */
  function handleSubmit() {
    setIsSubmitting(true);
    const {
      formValues, handleClose, mutate: mutateUnits, projectId, setIsLoading, showError, showSuccess
    } = data;

    handleCreateBulk({
      formValues,
      handleClose,
      hide,
      mutate: mutateUnits,
      projectId,
      setIsLoading,
      setIsSubmitting,
      showError,
      showSuccess
    });
  }

  return (
    <Modal hide={hide} show={show}>
      <div className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 sm:align-middle">

        <ModalCloseButton hide={hide} />

        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <AlertIcon />
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <ProjectUnitBulkCreation offerDataAvailable={watchData.formValues.offerDataAvailable} saleDataAvailable={watchData.formValues.saleDataAvailable} unit={watchData.formValues} />
          </div>
        </div>

        <div className="mt-5 gap-x-2 sm:mt-4 sm:flex sm:flex-row-reverse">
          <ModalSubmitButton color="bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-600" isSubmitting={isSubmitting} onClick={handleSubmit}>
            Erstellen
          </ModalSubmitButton>

          <ModalCancelButton hide={hide} />
        </div>

      </div>
    </Modal>
  );
};

export default CreateBulkModal;
