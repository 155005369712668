import { TriangleAlertIcon } from "lucide-react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { formatDate } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";

import LockBadgeSmall from "~/src/ui/badges/lock-badge-small/index.jsx";
import Badge from "~/src/ui/badges/status-badge/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";
import ProjectLink from "~/src/ui/project-link/index.jsx";
import ProjectLocation from "~/src/ui/project-location/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.isLoading - The root object
 * @param props0.projects - The root object
 * @example
 */
const FeedList = ({ isLoading, projects }) => {
  const { user } = useAuth();

  if (isLoading) {
    return <div className="mt-28"><Skeleton /><Skeleton /><Skeleton /></div>;
  }

  return (
    <div className="mt-8">
      <div className="flex items-end justify-between px-4 text-gray-600 sm:px-2 lg:px-0">
        <h2 className="text-xl">Projekte mit neuen Kaufverträgen: {projects?.length}</h2>

        <div className="flex flex-col gap-2">
          <div className="grid auto-cols-min grid-cols-[auto_1.25rem_auto] items-center gap-1 text-xs">
            <span>*</span>

            <TriangleAlertIcon className="size-5 text-red-500" />

            <span>ohne letztes Kontrolldatum</span>
          </div>
        </div>
      </div>

      <div className="rounded-xs my-3 grid grid-cols-2 overflow-hidden border-2 border-gray-200 md:grid-cols-1">

        <div className="grid-cols-16 hidden border-b-2 border-gray-200 md:grid">

          <div className="col-span-1 grid grid-cols-8 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-6">
            <div className="col-span-2 bg-gray-100 pr-1.5 text-left text-xs font-bold uppercase tracking-wider text-gray-500 xl:col-span-1">
              <p>ID</p>
            </div>

            <p>Name</p>
          </div>

          <div className="col-span-1 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-4 xl:col-span-5">
            <p>Adresse</p>
          </div>

          <div className="col-span-3 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 xl:col-span-2">
            <p>Datum</p>
          </div>

          <div className="col-span-2 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
            <p>Status</p>
          </div>

          <div className="col-span-1 whitespace-nowrap bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
            <p># KV</p>
          </div>

        </div>

        <div className="col-span-2 grid grid-cols-1 divide-y-2 divide-gray-200 md:col-span-1">
          {projects?.map((project) => {
            const isProjectEditor = project?.active_project_editor && project.active_project_editor.email === user?.email;
            const isProjectLocked = Boolean(project?.active_project_editor);
            const isUnitEditor = project?.active_unit_editor && project.active_unit_editor.email === user?.email;
            const isUnitLocked = Boolean(project?.active_unit_editor);

            return (
              <div
                className="md:grid-cols-16 col-span-1 flex flex-col truncate md:grid"
                key={`projects_${project.id}`}
              >

                <div className="relative col-span-1 grid grid-cols-8 truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-6">
                  <div className="absolute right-4 col-span-2 whitespace-nowrap py-4 pl-4 pr-1.5 text-sm font-medium text-gray-500 md:static md:p-0 xl:col-span-1">
                    {project?.id}
                  </div>

                  <div>
                    <ProjectLink project={project} />

                    {isProjectLocked
                      ? (
                        <div className="mt-1 flex items-center">
                          <LockBadgeSmall isEditor={isProjectEditor} isLocked={isProjectLocked} />

                          <span className="ml-1">{project?.active_project_editor?.username} (Projekterfassung)</span>
                        </div>
                      )
                      : null}

                    {isUnitLocked
                      ? (
                        <div className="mt-1 flex items-center">
                          <LockBadgeSmall isEditor={isUnitEditor} isLocked={isUnitLocked} />

                          <span className="ml-1">{project?.active_unit_editor?.username} (Objekterfassung)</span>
                        </div>
                      )
                      : null}
                  </div>

                </div>

                <div className="col-span-1 truncate whitespace-nowrap px-4 py-1 text-left text-sm text-gray-500 md:col-span-4 md:py-4 xl:col-span-5">
                  {
                    [...project?.locations?.slice(0, 3)]
                      .sort(({ default_location: defaultA }, { default_location: defaultB }) => (defaultA ? -1 : 0))
                      .map((location, index, array) => (
                        <Fragment key={`location_${location.id}`}>
                          <ProjectLocation location={location} />

                          {(index === array.length - 1 && project?.locations.length > array.length) && (
                            <Link className="underline" to={`/projects/${project.id}/project-infos`}>...</Link>
                          )}
                        </Fragment>
                      ))
                  }
                </div>

                <div className="col-span-3 truncate whitespace-nowrap px-4 py-1 text-left text-sm text-gray-500 md:col-span-3 md:py-4 xl:col-span-2">
                  <div>
                    <p className="text-xs font-medium text-gray-500">Letzte Aktualisierung:</p>

                    <p>
                      {project.last_contract_update && project.last_unit_sale_research_date
                        ? formatDate({
                          date: project.last_contract_update,
                          withTime: true
                        })
                        : "-"}
                    </p>
                  </div>

                  <div className="mt-2">
                    <p className="text-xs font-medium text-gray-500">Letzte Erfassung:</p>

                    <p>
                      {project.last_unit_sale_research_date
                        ? formatDate({
                          date: project.last_unit_sale_research_date,
                          withTime: true
                        })
                        : "-"}
                    </p>
                  </div>

                </div>

                <div className="col-span-1 flex flex-col items-start gap-1 truncate whitespace-nowrap px-4 pb-2 pt-1 text-left text-sm text-gray-500 md:col-span-2 md:py-4">
                  {
                    project.published
                      ? <Badge badgeFor="public" text="Öffentlich" />
                      : <Badge badgeFor="internal" text="Intern" />
                  }

                  {(project.published && project.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
                </div>

                <div className="col-span-1 flex flex-row items-center gap-2 truncate whitespace-nowrap px-4 pb-2 pt-1 text-left text-sm text-gray-500 md:col-span-1 md:flex-col md:py-4">
                  {
                    project.last_unit_sale_research_date
                      ? (
                        <Link
                          className="truncate font-medium text-gray-500 underline hover:no-underline"
                          to={`/projects/${project.id}/project-contracts`}
                        >
                          <span>{project.new_contracts_count}</span>
                        </Link>
                      )
                      : null
                  }

                  {
                    !project.last_unit_sale_research_date && (
                      <Link
                        className="truncate font-medium text-gray-500 underline hover:no-underline"
                        to={`/projects/${project.id}/project-contracts`}
                      >
                        <TriangleAlertIcon className="size-5 text-red-500" />
                      </Link>
                    )
                  }
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </div>

  );
};

export default FeedList;
