import { useParams } from "react-router-dom";

import ProjectNotes from "~/src/features/project-overview/project-notes/index.jsx";
import ProjectPublishOverview from "~/src/features/project-overview/project-publish-overview/index.jsx";
import ProjectResearchState from "~/src/features/project-overview/project-research-state/index.jsx";
import ProjectReview from "~/src/features/project-overview/project-review/index.jsx";
import UnitNotes from "~/src/features/project-overview/project-unit-notes/index.jsx";
import ResearchNotes from "~/src/features/project-overview/research-notes/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import TabHeader from "~/src/ui/headers/tab-header/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

import UnitReview from "../../features/project-overview/unit-review/index.jsx";
// import ProjectTrackingOverview from "~/src/features/project-overview/project-tracking-overview"

/**
 *
 * @example
 */
const ProjectOverviewPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <>
      <TabHeader>Übersicht</TabHeader>

      <ul className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

        <ProjectPublishOverview />

        {/* <ProjectTrackingOverview /> */}

      </ul>

      <div className="mt-12 divide-y divide-gray-200">
        <ProjectResearchState />

        <ProjectReview />

        <UnitReview />

        <ProjectNotes />

        <UnitNotes />

        <ResearchNotes />
      </div>
    </>
  );
};

export default ProjectOverviewPage;
