import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.isLoading - The root object
 * @param props0.loadedProject - The root object
 * @param props0.state - The root object
 * @example
 */
const ProjectLocations = ({
  isLoading,
  loadedProject: project
}) => {
  if (project && project.locations) {
    return project.locations.map((location) => (
      <div className="flex items-center" key={`location_${location.id}`}>
        <p className={cn("text-gray-500", { "bg-red-500/50": location.broken })}>
          {location.stateNameShort?.toUpperCase()}, {location.zipcodeZipcode} {location.cityName}, {location.streetName} {location.housenumber}
        </p>
      </div>
    ));
  }

  return null;
};

export default ProjectLocations;
